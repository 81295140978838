import React, { useState, useContext,useEffect } from 'react';
import { Stepper, Step, StepLabel, Button, Box, Grid,Container, CircularProgress, Card, CardContent } from '@mui/material';
import { Formik, Form } from 'formik';
import AddressForm from './Forms/BasicInfo';
import RiskAversion from './Forms/RiskAversion'
import LossAversion from './Forms/LossAversion';
import Reflection from './Forms/Reflection';
import CheckoutSuccess from './QuestionnaireSuccess';
import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';
import { useSelector } from 'react-redux';
import { useThunk } from '../../../hooks/use-thunk'
import { addUserQnsResp,getUserQnsStatus } from '../../../store';
import {AuthContext} from '../../Context/AuthContext'
import { FormContext } from '../Questionnaire';
import ScheduleAppointment from '../../AppointMent/AppointMent/ScheduleAppointment'

const steps = ['Personal Information', 'Risk Aversion', 'Loss Aversion', 'Reflection'];



const { formId, formField } = checkoutFormModel;

function _renderStepContent(step,values) {
  switch (step) {
    case 0:
      return <AddressForm formField={formField} values={values} />;
    case 1:
      return <RiskAversion formField={formField} />;
    case 2:
      return <LossAversion formField={formField} />;
    case 3:
      return <Reflection formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

export default function QuestionnairePage(props) {
  const [doAddUserQnsResp, isAddingUserQnsResp, addUserQnsRespError] = useThunk(addUserQnsResp);
  const [fetchUserQnsStatus] = useThunk(getUserQnsStatus);
  const { user } = useContext(AuthContext);
  const { formData, setFormData } = useContext(FormContext);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { data } = useSelector((state) => {
    return state.questionnaire;
  });
  
  useEffect(() => {    
    fetchUserQnsStatus({client_email_id:user.email})
  },[user.email])

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async function _submitForm(values, actions) {
    doAddUserQnsResp({ "user": user, "qdata": values });      
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {    
    setFormData({ ...values, ...formData })
    if (isLastStep) {
      _submitForm({ ...values, ...formData }, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <Container maxWidth="lg" sx={{paddingBottom:2}}>
    <Grid container spacing={3}>
      { data?.data?.length <= 0 ? (
      <Grid item xs={12} sm={12}>
        <CardContent>
          <Stepper activeStep={activeStep}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                fontSize:'1.125rem',
                color: 'primary.light', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                fontSize:'1.125rem',
                color: 'grey.500', // Just text label (COMPLETED)
              },
              '& .MuiStepLabel-root .Mui-active': {
                fontSize:'1.125rem',
                color: '#70C1B3', // circle color (ACTIVE)
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                fontSize:'1.125rem',
                color: 'common.white', // Just text label (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fontSize:'1.25rem'                
              },
            }}
            >

            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Grid>
      ):(<></>)}
      <Grid item xs={12} sm={12}>
        {activeStep === steps.length || data?.data?.length > 0 ? (
          <>
          <CheckoutSuccess />
          <ScheduleAppointment qdata={formData} payment={props}/>
          </>
        ) : (
          <Formik
            initialValues={formInitialValues}
            validateOnChange={false}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({  values,isSubmitting }) => (
              <Form id={formId} sx={{ minHeight: 400 }}>
                <Card>
                  <CardContent>
                    {_renderStepContent(activeStep,values)}
                  </CardContent>
                </Card>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack}>
                      Back
                    </Button>
                  )}
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    //variant="contained"
                    //color="primary"
                  >
                    {isLastStep ? 'Submit Response' : 'Next'}
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                    />
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Grid>
   </Grid>
   </Container>
    );
}
