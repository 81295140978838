import React from 'react';
import './Footer.css';
import { Card, Box, Divider, Link, CardContent, Container, Grid, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faHandshake, faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faYoutube, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons"
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const Footer = () => {

    return (
        <footer className="footer-area">
            <Container maxWidth="lg">
                <CardContent>
                    <Grid container>
                        <Grid item xs={4} textAlign={'left'} sx={{alignItems:"end"}}>
                            <Typography variant="body2" gutterBottom>
                                Find Us on Social Media
                            </Typography>
                            <Box sx={{ display: 'inline-flex', width: 'fit-content', justifyContent:'center' }}>
                            <Link sx={{margin:"0 10px"}} href="https://www.linkedin.com/company/story-makers-investment-advisors/" target="_blank" underline="none" variant="body2">
                                <Typography variant="h5">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </Typography>
                            </Link>
                            <Link href="https://www.youtube.com/channel/UCXHMptQrz2wLYQDvrPDyjQw" underline="none" variant="body2">
                                <Typography variant="h5">
                                    <FontAwesomeIcon icon={faYoutubeSquare} />
                                </Typography>
                            </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" gutterBottom>
                                Hours: Weekends
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Call us: (720) 689-3917
                            </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign={"end"}>
                            <Box sx={{ display: 'inline-flex', width: 'fit-content' }}>
                                <Link href="/files/Privacy-Policy.pdf" target="_blank" download underline="none" color={"secondary"} variant="body2">
                                    Privacy Policy
                                </Link>
                                <Divider orientation="vertical" flexItem sx={{ borderColor: "#fff", marginLeft: 1, marginRight: 1 }} />
                                <Link href="/files/Brochure&Brochure-Supplement.pdf" target="_blank" download underline="none" color={"secondary"} variant="body2">
                                    Brochure & Brochure Supplement
                                </Link>
                            </Box>
                            <Typography variant='body2'>
                                © 2023 Story Makers, LLC. All Rights Reserved.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Container>

        </footer>
    );
};

export default Footer;