import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const fetchUserPayment = createAsyncThunk('payment/fetch', async (data) => {
  const response = await axios.post(`${baseUrl}/v1/getUserPayment`, data);
  return response.data;
});

const addUserPayment = createAsyncThunk('payment/add', async (data) => {
    const response = await axios.post(`${baseUrl}/v1/addUserPayment`, data);
    return response.data;
  });

export { fetchUserPayment,addUserPayment };