import { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import CheckoutForm from "./CheckoutForm";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Check from '@mui/icons-material/Check';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QuizIcon from '@mui/icons-material/Quiz';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {BrowserView, MobileView} from 'react-device-detect';
import {PDFReader, MobilePDFReader } from 'react-read-pdf';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import {
  Box, Card, DialogActions, Dialog, DialogContent, Stepper, Step, StepLabel, StepContent,
  Paper,stepConnectorClasses,StepConnector,
  DialogTitle, Stack, Button, CardContent
} from "@mui/material";
import { Fragment } from "react";
import { AuthContext } from "../Context/AuthContext";
import './payment.css'

const baseUrl = process.env.REACT_APP_BASE_URL;
function Payment(props) {
  const { user } = useContext(AuthContext);
  const steps = ['Service State', 'Qualification Confirmation for Investment Advising Services', 'Privacy Policy', 'Broucher & Broucher Supplement'];
  const [open, setOpen] = useState(true)
  const navigate = useNavigate();
  const [scroll, setScroll] = useState('paper');
  const [activeStep, setActiveStep] = useState(0);
  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const myAppointments = () => {
    navigate("/MyAppointments");
  }
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      background:'#35825b',
    }),
  }));
  
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: <PaymentsIcon />,
      2: <QuizIcon />,
      3: <EventAvailableIcon />,
      4: <CheckCircleIcon />
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed? icons[4] : icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const progressSteps = ['Make Payment', 'Submit Questionnaire', 'Choose Appointment Date and Time'];
  return (
    <Fragment>
      {!props?.data?.get("session_id") && (
        <Dialog fullScreen
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle color="primary" sx={{ marginLeft: "8px" }}>User Terms</DialogTitle>
          <DialogContent>
            <Stepper activeStep={activeStep} orientation="vertical"
              sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  fontSize: '1.125rem',
                  color: 'primary.light', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  fontSize: '1.125rem',
                  color: 'grey.500', // Just text label (COMPLETED)
                },
                '& .MuiStepLabel-root .Mui-active': {
                  fontSize: '1.125rem',
                  color: '#70C1B3', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  fontSize: '1.125rem',
                  color: 'common.white', // Just text label (ACTIVE)
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fontSize: '1.25rem'
                },
              }}
            >
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel sx={{ background: "#e8eeec", margin: "8px", paddingLeft: "8px" }}>
                    {step}
                  </StepLabel>
                  <StepContent>
                    {
                      index === 0 && (
                        <><Typography color="primary" variant="body2 warning" gutterBottom>
                          Attention: Residents of Texas and Louisiana</Typography>
                          <Typography color="primary" variant="body2" gutterBottom>Thank you for visiting Story Makers Investment Advisors, a registered investment advisor (RIA) business. We strive to provide exceptional service to our clients. However, please note that at this time, we are unable to offer our services to any residents of Texas and Louisiana as we are not registered in those states.
                          </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>To proceed further, we kindly request your cooperation in confirming that you do not reside in either Texas or Louisiana. By clicking "Accept & Proceed" below, you acknowledge that you are not a resident of Texas or Louisiana, and you understand that we are unable to meet with clients from these states.
                          </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>Please note that providing false information may lead to legal implications. If you are unsure about your residency status or have any questions, we recommend consulting with a legal professional.
                          </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>Thank you for your understanding and cooperation.</Typography>
                        </>
                      )
                    }

                    {
                      index === 1 && (
                        <><Typography color="primary" variant="body2" gutterBottom>At Story Makers Investment Advisors, we strive to provide tailored investment advising services to clients with a certain level of analytical sophistication. To ensure that our services align with your needs, please confirm your eligibility by checking below that you meet one of the following criteria:
                        </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>I am a Data Scientist or work in a similar profession such as Mathematician, Statistician, Actuary, Engineer, etc.
                          </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>I hold an advanced degree in a quantitative field (e.g., Mathematics, Statistics, Engineering, Economics, etc.).
                          </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>By clicking "Accept & Proceed", you acknowledge that you meet the necessary criteria for receiving our investment advising services based on your ability to comprehend and complete the forthcoming detailed questionnaire.
                          </Typography>

                          <Typography color="primary" variant="body2" gutterBottom>Please note that the questionnaire plays a crucial role in tailoring our services to your specific needs. Providing false information or misrepresenting your level of analytical proficiency may lead to inaccurate recommendations.
                          </Typography>
                          <Typography color="primary" variant="body2" gutterBottom>Thank you for your understanding and cooperation.</Typography>
                        </>
                      )
                    }
                    {
                      index === 2 && (
                        <div style={{overflow:'scroll',height:'90%'}}>
                        <BrowserView>
                        <embed src="/files/Privacy-Policy.pdf" width="100%" height="500px" />
                        </BrowserView>
                        <MobileView>
                            <MobilePDFReader url="/files/Privacy-Policy.pdf" page={2}/>
                        </MobileView>                        
                        </div>
                        // <embed src="/files/Privacy-Policy.pdf" width="100%" height="500px" />
                      )
                    }
                    {
                      index === 3 && (
                        <div style={{overflow:'scroll',height:'90%'}}>
                        <BrowserView>
                        <embed src="/files/Brochure&Brochure-Supplement.pdf" width="100%" height="500px" />
                          {/* <PDFReader url="/files/Brochure&Brochure-Supplement.pdf" page={14}/> */}
                        </BrowserView>
                        <MobileView>
                            <MobilePDFReader url="/files/Brochure&Brochure-Supplement.pdf" page={14}/>
                        </MobileView>                        
                        </div>                        
                      )
                    }

                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>

                        <Button
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? 'Accept & Proceed' : 'Accept & Proceed'}
                        </Button>

                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Stack
          xs={{ pt: 4, pb: 2 }}
          md={{ pt: 4, mb: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Typography color="primary" variant="body1">You have completed all the documents and agree to Terms.</Typography>
              
        </Stack>
              
              )}
          </DialogContent>
          <DialogActions>
            {activeStep === steps.length && (<>
              {/* <Typography color="primary">You have completed all the documents and agree with it.</Typography> */}
              <Button onClick={handleReset}>
                Reset
              </Button>
              <Button onClick={handleClose}>Next</Button>
            </>
            )}

          </DialogActions>
        </Dialog>
        )}
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: "#E8EEEC" }}>
        <Stack
          xs={{ pt: 4, pb: 2 }}
          md={{ pt: 4, mb: 4 }}
          direction="row"
          spacing={2}
          justifyContent="right"
        >
          <Button size="small" onClick={myAppointments}>My Appointments</Button>
        </Stack>        
       
        {/* <Stack
          xs={{ pt: 4, pb: 2 }}
          md={{ pt: 4, mb: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >         
        <Stepper alternativeLabel activeStep={2} connector={<ColorlibConnector />}>
        {progressSteps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
          
        </Stack>  */}
        
        <CheckoutForm sx={{ p: 4 }} paymentStatus={props?.data?.get("session_id")} />
        
      </Box>
    </Fragment>
  );
}

export default Payment;