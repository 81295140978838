import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import useFetch from '../../../hooks/useFetch';
import { useState, useContext, useEffect } from "react";
import { FormContext } from '../Questionnaire';
const baseUrl = process.env.REACT_APP_BASE_URL;

export default function DataTable(props) {
    const { qdata, income, groupingCols } = props;
    const { formData, setFormData } = useContext(FormContext);

    const [grossIncome, setGrossIncome] = useState(income);
    const [selectedData, setSelectedData] = useState(qdata.datakey);
    const [isSelected, setIsSelected] = useState(false);
    const { data, loading, error } = useFetch(`${baseUrl}/v1/${qdata.api}`, { gross_income: grossIncome, thresold: qdata.thresold });

    const [tableData, setTableData] = useState([]);
    function selectedInput(d) {
        setIsSelected(true)
        let tmpData = data.map(row=>{
            if ((qdata.api).includes('RiskAversion')) {
                d.row.preference ='Prefer B over A'
                row.preference = +(row.p) >= +(d.row.p) ? 'Prefer B over A' : 'Prefer A over B'
            }
            else {
                d.row.preference = 'Do Not Play'
                row.preference = +(row.p) >= +(d.row.p) ? 'Play' : 'Do Not Play'
            }
            return row;
          })
        setTableData(tmpData)
        setSelectedData(d.row);
        setFormData({ ...formData, ...{ [qdata.api]: d.row } })
    }

    const colGroups = [{ field: 'p', headerName: 'P', },
    {
        groupId: "A", headerAlign: 'center', headerClassName: 'super-app-theme-A-header',
        children: [{ field: 'aew', headerName: 'E[winnings]' },
        { field: 'aw1p', headerName: 'Win with Probability 1 - p ' },
        { field: 'awp', headerName: 'Win with Probability p' }]
    },
    {
        groupId: "B", headerAlign: 'center', headerClassName: 'super-app-theme-B-header', children: [{ field: 'bew', headerName: 'E[winnings]' },
        { field: 'bw1p', headerName: 'Win with Probability 1 - p ' },
        { field: 'bwp', headerName: 'Win with Probability p' }]
    }, { field: 'preference', headerName: '', }]

    return (
        <Box paddingBottom={2}>
            <Typography variant="h6" color="primary" gutterBottom>{qdata.question}</Typography>
            <br />
            <Grid container p={1}>
                <Grid xs={12} sx={{
                    // height: 860,
                    width: '100%',
                    '& .super-app-theme-A-header': {
                        backgroundColor: 'rgb(255, 224, 102, 26%)',
                    },
                    '& .super-app-theme-B-header': {
                        backgroundColor: 'rgb(111, 193, 179, 26%)',
                    },
                    '& .super-app-theme-A-cell': {
                        backgroundColor: 'rgb(255, 224, 102, 26%)',
                        fontWeight: '600',
                    },
                    '& .super-app-theme-B-cell': {
                        backgroundColor: 'rgb(111, 193, 179, 26%)',
                        fontWeight: '600',
                    },
                    '& .MuiDataGrid-cell:hover': {
                        backgroundColor: 'rgb(53,130,91,25%)',
                        color: "#fff"
                    },
                }}>
                    <DataGrid sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          } }}          rowHeight={21}
                        rows={(tableData?.length > 0) ? tableData : data}
                        columns={qdata.columns}
                        getRowId={(row) => row.p}
                        experimentalFeatures={{ columnGrouping: groupingCols }}
                        columnGroupingModel={colGroups}
                        enablePagination={false}
                        rowsPerPageOptions={[]}
                        onRowClick={selectedInput}
                        disableMultipleSelection={true}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}