import React, { useContext, useState, useEffect, Fragment } from "react";
import moment from "moment";
import {
  Grid,
  makeStyles,
  Card,
  Button,
  CircularProgress,
  Popover
} from "@material-ui/core";
import './AppointMentTop.css';
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import IconButton from '@mui/material/IconButton';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import { useSelector } from 'react-redux';
import { useThunk } from '../../../hooks/use-thunk'
import { AuthContext } from '../../Context/AuthContext';
import { fetchAppointments, addAppointment } from '../../../store'
import { AppointmentContext } from '../../Context/AppointmentContext';
import { FormContext } from "../../Questionnaire/Questionnaire";
import { Typography, List, ListItem, Container, ThemeProvider,Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText,
   CssBaseline, Avatar, ListItemAvatar, ListItemText, Divider, CardContent } from '@mui/material';
import john from './../../../images/john-henry.png'
import isNull from 'lodash';
import { StoryMakersTheme } from "../../../theme/storyMakersTheme";
import { PaymentContext } from "../../Context/PaymentContext";
import './AppointMentTop.css';
import { useNavigate } from "react-router-dom";


const CalendarTemplate = ({
  availability,
  setAvailability,
  primaryColor = "#CCCCCC",
  secondaryColor = "#EEEEEE",
  primaryFontColor = "#444444",
  fontFamily = "Roboto",
  fontSize = 12,
  startTime = "6:00",
  endTime = "20:00",
}) => {

  const theme = StoryMakersTheme;

  const useStyles = makeStyles((theme) => ({
    calendar: {
      fontFamily: 'StoryMakers'
    },
    calendarText: {
      margin: 0,
      width: 25,
      height: 25,
      [StoryMakersTheme.breakpoints.up('xs')]: {
        width: 12,
        height: 12
      },
      [StoryMakersTheme.breakpoints.up('sm')]: {
        width: 16,
        height: 16
      },
      textAlign: "center",
    },
    button: {
      minWidth: 120,
      margin: 6,
      color: "#50514f"
    },
    buttonNoMargin: {
      minWidth: 200
    },
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: 2,
    },
    cardMinHeight: {
      
      [StoryMakersTheme.breakpoints.up('md')]: {
        minHeight: 350,
        padding:8
      },
      margin: 2
    }
  }));

  const useMonths = (year) => ({
    1: {
      lastDay: 31,
      month: "January",
      firstDay: moment(`01/01/${year}`),
    },
    2: {
      lastDay: year % 4 === 0 ? 29 : 28,
      month: "February",
      firstDay: moment(`02/01/${year}`),
    },
    3: {
      lastDay: 31,
      month: "March",
      firstDay: moment(`03/01/${year}`),
    },
    4: {
      lastDay: 30,
      month: "April",
      firstDay: moment(`04/01/${year}`),
    },
    5: {
      lastDay: 31,
      month: "May",
      firstDay: moment(`05/01/${year}`),
    },
    6: {
      lastDay: 30,
      month: "June",
      firstDay: moment(`06/01/${year}`),
    },
    7: {
      lastDay: 31,
      month: "July",
      firstDay: moment(`07/01/${year}`),
    },
    8: {
      lastDay: 31,
      month: "August",
      firstDay: moment(`08/01/${year}`),
    },
    9: {
      lastDay: 30,
      month: "September",
      firstDay: moment(`09/01/${year}`),
    },
    10: {
      lastDay: 31,
      month: "October",
      firstDay: moment(`10/01/${year}`),
    },
    11: {
      lastDay: 30,
      month: "November",
      firstDay: moment(`11/01/${year}`),
    },
    12: {
      lastDay: 31,
      month: "December",
      firstDay: moment(`12/01/${year}`),
    },
  });

  const getDefaultTimes = () => {
    const times = [
      {
        time: "0:00",
        available: false,
      },
      {
        time: "1:00",
        available: false,
      },
      {
        time: "2:00",
        available: false,
      },
      {
        time: "3:00",
        available: false,
      },
      {
        time: "4:00",
        available: false,
      },
      {
        time: "5:00",
        available: false,
      },
      {
        time: "6:00",
        available: false,
      },
      {
        time: "6:30",
        available: false,
      },
      {
        time: "7:00",
        available: false,
      },
      {
        time: "7:30",
        available: false,
      },
      {
        time: "8:00",
        available: false,
      },
      {
        time: "8:30",
        available: false,
      },
      {
        time: "9:00",
        available: false,
      },
      {
        time: "9:30",
        available: false,
      },
      {
        time: "10:00",
        available: false,
      },
      {
        time: "10:30",
        available: false,
      },
      {
        time: "11:00",
        available: false,
      },
      {
        time: "11:30",
        available: false,
      },
      {
        time: "12:00",
        available: false,
      },
      {
        time: "12:30",
        available: false,
      },
      {
        time: "13:00",
        available: false,
      },
      {
        time: "13:30",
        available: false,
      },
      {
        time: "14:00",
        available: false,
      },
      {
        time: "14:30",
        available: false,
      },
      {
        time: "15:00",
        available: false,
      },
      {
        time: "15:30",
        available: false,
      },
      {
        time: "16:00",
        available: false,
      },
      {
        time: "16:30",
        available: false,
      },
      {
        time: "17:00",
        available: false,
      },
      {
        time: "17:30",
        available: false,
      },
      {
        time: "18:00",
        available: false,
      },
      {
        time: "18:30",
        available: false,
      },
      {
        time: "19:00",
        available: false,
      },
      {
        time: "19:30",
        available: false,
      },
      {
        time: "20:00",
        available: false,
      },
      {
        time: "21:00",
        available: false,
      },
      {
        time: "22:00",
        available: false,
      },
      {
        time: "23:00",
        available: false,
      },
      {
        time: "0:00",
        available: false,
      },
    ];
    let include = false;
    return times.filter(time => {
      if (time.time === startTime) {
        include = true;
      }
      if (time.time === endTime) {
        include = false;
        return true;
      }
      return include;
    })
  };

  function TimeButton({ className, start, end, available, handleClick }) {
    return (
      <Button
        onClick={handleClick}
        color={available ? "primary" : "default"}
        className={available ? 'booked' : 'available'}
        variant={available ? "contained" : "outlined"}
        disabled={available =='booked'?true:false}
      >
        {start} - {end}
      </Button>
    ); 
  }

  function getDaysArray() {
    return [
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
      ["", "", "", "", "", "", ""],
    ];
  }

  const convertAvailabilityFromDatabase = (availability) => {
    const output = {};
    for (let range of availability) {      
      let start = moment(range.start);
      let startTime = `${start.format("H")}:${start.format("mm")}`;
      let end = moment(range.end);
      let endTime = `${end.format("H")}:${end.format("mm")}`;
      let year = Number(start.format("YYYY"));
      let month = start.format("MMMM");
      let day = Number(start.format("D"));
      fillOutputWithDefaultTimes(output, year, month, day);
      let i = 0;
      while (
        i < output[year][month][day].length &&
        output[year][month][day][i].time !== startTime
      )
        i++;
      while (
        i < output[year][month][day].length &&
        output[year][month][day][i].time !== endTime
      ) {
        output[year][month][day][i].available = 'booked';
        i++;
      }
    }
    return output;
  };

  const convertAvailabilityForDatabase = (availability) => {

    const output = [];
    for (let year in availability) {
      for (let month in availability[year]) {
        for (let day in availability[year][month]) {
          let activeDay = availability[year][month][day];
          addActiveDayToOutput(activeDay, output, month, day, year);
        }
      }
    }
    return output;
  };

  const combineTimeArrays = (a, b) => {
    for (let i = 0; i < a.length; i++) {
      a[i].available = a[i].available || b[i].available;
    }
    return a;
  };
  function addActiveDayToOutput(activeDay, output, month, day, year) {
    let activeRangeStart = null;
    for (let time of activeDay) {
      if (time.available && !activeRangeStart) activeRangeStart = time.time;
      else if (!time.available && activeRangeStart) {
        output.push({
          start: new Date(`${month} ${day} ${year} ${activeRangeStart}`),
          end: new Date(`${month} ${day} ${year} ${time.time}`),
        });
        activeRangeStart = null;
      }
    }

  }

  function fillOutputWithDefaultTimes(output, year, month, day) {
    if (output.hasOwnProperty(year)) {
      if (output[year].hasOwnProperty(month)) {
        if (!output[year][month].hasOwnProperty(day)) {
          output[year][month][day] = getDefaultTimes();
        }
      } else {
        output[year][month] = {
          [day]: getDefaultTimes(),
        };
      }
    } else {
      output[year] = {
        [month]: {
          [day]: getDefaultTimes(),
        },
      };
    }
  }

  function makeQuickAvailability(availability) {
    const output = {};
    for (let range of availability) {
      if (new Date(range.start) > new Date()) {
        let day = moment(range.start).format("MMMM D, YYYY");
        let time = `${moment(range.start).format("H:mm")} - ${moment(
          range.end
        ).format("H:mm")}`;
        if (output[day]) {
          output[day].push(time);
        } else {
          output[day] = [time];
        }
      }
    }
    return output;
  }
  return function Calendar(props) {
    
    const classes = useStyles();
    const today = moment();    
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; 
    const fixedDate = moment('2023-09-23', 'YYYY-MM-DD');
    const maxDate = moment.max([today.add(7,'days'),fixedDate]);
    const weekend = ['6', '0'];
    const [doCreateAppointment, isCreatingAppointment, creatingAppointmentError] = useThunk(addAppointment);
    const [doFetchAppointments] = useThunk(fetchAppointments);
    const stateObj = useSelector((state) => {
      return state;
    });
    
    useEffect(() => {
      doFetchAppointments();
    }, [doFetchAppointments,creatingAppointmentError]);

    let fixed_appointments = stateObj.appointments.data.map(d=>{
      let start = new Date(d.appointment_time);
      return {start: start,end: new Date(start.getTime()+30*60000) };
    })
 
    const [availability, setAvailability] = useState(fixed_appointments)
    const [open, setOpen] = React.useState(false); 
    const navigate = useNavigate()

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

 
    const { state, dispatch } = useContext(AppointmentContext)

    const [availabilityState, setAvailabilityState] = useState(
      convertAvailabilityFromDatabase(availability)
    );

    const [quickAvailability, setQuickAvailability] = useState(
      makeQuickAvailability(availability)
    );
    const [activeDay, setActiveDay] = useState(null);
    const [modalState, setModalState] = useState(false)
    const [year, setYear] = useState(Number(today.format("YYYY")));
    const [monthNumber, setMonthNumber] = useState(Number(today.format("M")));
    const [settingMultiple, setSettingMultiple] = useState(false);
    const months = useMonths(year);
    const { firstDay, month, lastDay } = months[monthNumber];
    let dayOfWeek = Number(moment(firstDay).format("d"));
    const days = getDaysArray();
    const closeModal = () => setModalState(false);
    const [times, setTimes] = useState(getDefaultTimes());
    const [saving, setSaving] = useState(false);
    const error = useSelector(state => state.appointments.error);

    const { user } = useContext(AuthContext);
    const [appintmentData, setAppointmentData] = useState({});
    const ref = React.useRef(null);

    const Item = (props) => {
      const [open, setOpen] = useState(false);
      const [selectedConsultant, setSelectedConsultant] = useState({})
      const { state, dispatch } = useContext(AppointmentContext)
      // console.log(props)
      // dispatch({ type: "SELECTED_CONSULTANT", payload: props })
      const handleClick = (e) => {
        setOpen((prev) => !prev);
        setSelectedConsultant(e);
        console.log(e)
        dispatch({ type: "SELECTED_CONSULTANT", payload: e })
      };
      
      return (
        <ListItem key={props.data.name} onClick={(e) => handleClick(props)}
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              {/* {(props.data.name == state?.consultant?.data?.name) ? <CheckCircle /> : <CheckBoxOutlineBlank />} */}
            <CheckCircle/>
            </IconButton>
          }>
          <ListItemAvatar>
            <Avatar alt="Profile Picture" src={john} />
          </ListItemAvatar>
          <ListItemText color="primary" secondary={props.data.name}/>          
        </ListItem>
      );
    };

    let week = 0;
    let dayOfMonth = 1;
    while (week < 6 && dayOfMonth <= lastDay) {
      days[week][dayOfWeek] = dayOfMonth;
      dayOfMonth++;
      dayOfWeek++;
      if (dayOfWeek === 7) {
        week++;
        dayOfWeek = 0;
      }
    }
    const createArrowHandler = (delta) => () => {
      let newMonth = monthNumber + delta;
      if (newMonth > 12) {
        setYear(year + 1);
        newMonth = 1;
      } else if (newMonth < 1) {
        setYear(year - 1);
        newMonth = 12;
      }
      setActiveDay(null);
      setTimes(getDefaultTimes());
      setMonthNumber(newMonth);
    };

    const createTimeHandler = (i) => () => {
      const newTimes = [...times];
      newTimes.forEach(function(a) {
        a.available = a.available=='booked'? 'booked':false;
      });
      
      newTimes[i].available = !newTimes[i].available;
      if (activeDay) {
        addTimeToDay(newTimes);
      }
      setTimes(newTimes);
      let selectedTime = newTimes.filter((t) => t.available == true)?.[0]?.time;
      dispatch({ type: "SET_APPOINTMENT_TIME", payload: selectedTime })
    };
    const createDayHandler = (day) => () => {

      if (settingMultiple) {
        addTimesToDay(day);
      } else {
        examineAvailabilityForDay(day);
      }
    }; 
    const handleSaveAvailability = () => {           
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;     
      doCreateAppointment({ "user": user, "timezone":timezone ,"appointment": state, "qdata": props.data.qdata,"payment":props.data.payment })      
      setOpen(true)
      setModalState(true);  
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState(null);
    const handleOpenPopover = (date) => {
      return (e) => {
        if (quickAvailability[date]) {
          setPopoverContent(
            quickAvailability[date].map((time) => <p>{time}</p>)
          );
          setAnchorEl(e.target);
        }
      };
    };
    const handleClosePopover = () => {
      setAnchorEl(null);
      setPopoverContent(null);
    };
     return (<>
          <Card>
            <CardContent sx={{padding: { md: 2,xs:0 }}}>
              <Container maxWidth="md">
                <Grid
                  className={classes.calendar}
                  container
                  direction="column"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Grid item={true} xs={12} sm={12} ref={ref} color="primary">
                        <Typography variant="h6" color="primary" p={2}>
                          Choose investment advisor
                        </Typography>
                        <Card className="h-400">
                          <CardContent sx={{padding:0,paddingBottom:'0px !important'}}>
                            <List>
                              {props.advisers && props.advisers.map((consultant, index) => (
                                <Item key={index} data={consultant} />
                              ))}
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6} container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography variant="h6" p={2} color="primary">Choose appintment date</Typography>
                          <Card
                            className={classes.cardMinHeight}
                          >
                            <Grid container justifyContent="center" sx={{ padding: { md: 2 }, margin: { md: 2 } }}>
                              <Grid item xs={2}>
                                <IconButton
                                  disabled={
                                    year === Number(today.format("YYYY")) &&
                                    month === today.format("MMMM")
                                  }
                                  onClick={createArrowHandler(-1)}
                                >
                                  <ArrowLeft />
                                </IconButton>
                              </Grid>
                              <Grid item xs={8} >
                                <Typography variant="h6" color="primary" textAlign="center">
                                  {month} {year}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} color="success">
                                <IconButton onClick={createArrowHandler(1)}>
                                  <ArrowRight />
                                </IconButton>
                              </Grid>
                              <Grid item xs={12} sx={{ justifyContent: "center" }} id="calDaypicker">
                                <Grid container direction="row" justifyContent="center">
                                  {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, i) => (
                                    <Grid key={day} item>
                                      <IconButton disableRipple className="booking-days">
                                        <p 
                                        className={classes.calendarText}
                                        >{day}</p>
                                      </IconButton>
                                    </Grid>
                                  ))}
                                </Grid>
                                <Grid container direction="row" id="calenderDays">
                                  {days.map((week, i) => (
                                    <Grid key={i} item>
                                      <Grid container direction="row">
                                        {week.map((day, i) => (
                                          <Grid key={year + month + i} item>
                                            <IconButton
                                              disableRipple
                                              className={((weekend.indexOf(moment(year+"-"+month+"-"+day).format("d")) >=0) &&                                               
                                              (moment(year+"-"+month+"-"+day) >= maxDate)) ? "booking-days-enable":"booking-days-disable"}                                              
                                              onClick={createDayHandler(day)}
                                              color={
                                                activeDay === day
                                                  ? "primary"
                                                  : availabilityState[year] &&
                                                    availabilityState[year][month] &&
                                                    availabilityState[year][month][day] &&
                                                    availabilityState[year][month][
                                                      day
                                                    ].filter((x) => x.available).length > 0
                                                    ? "default"
                                                    : "secondary"
                                              }

                                              disabled={
                                                !((weekend.indexOf(moment(year+"-"+month+"-"+day).format("d")) >=0) 
                                                 &&                                               
                                                 (moment(year+"-"+month+"-"+day) >= maxDate))
                                                }
                                              size="medium"
                                              // onMouseEnter={handleOpenPopover(
                                              //   `${month} ${day}, ${year}`
                                              // )}
                                              // onMouseLeave={handleClosePopover}
                                            >                                              
                                              <p 
                                              className={classes.calendarText}
                                              >{day}</p>
                                            </IconButton>
                                          </Grid>
                                        ))}

                                      </Grid>
                                    </Grid>
                                  ))}
                                  {/* <Popover
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    className={classes.popover}
                                    classes={{ paper: classes.paper }}
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                  >
                                    {popoverContent}
                                  </Popover> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" p={2} color="primary">Choose available slots (timezone: {timezone})</Typography>
                        <Card
                          className={classes.cardMinHeight}
                        >
                          <Grid container sx={{ justifyContent: "center", wrap: "wrap" }}>
                            <Grid item xs={12} className="timecontainer">
                              <Grid container justifyContent="center" sx={{ justifyContent: "center", padding: 3 }}>
                                {times.map((time, i) => i < times.length - 1 && (
                                  <Grid item>
                                    <TimeButton
                                      key={time.time}
                                      className={classes.button}
                                      start={time.time}
                                      end={times[i + 1].time}
                                      handleClick={createTimeHandler(i)}
                                      available={time.available}
                                    />
                                  </Grid>
                                )
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" style={{ justifyContent: "center", margin: 15 }}>
                      <Grid item>
                        {saving ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            //color="primary"
                            //variant="contained"
                            onClick={handleSaveAvailability}
                            className={((state.appointmentDate == null) || (state.appointmenttime ==null) || (state.consultant == null && state.consultant?.data.id > 0)) ? "":"appointmentSelected"}
                            disabled={((state.appointmentDate == null) || (state.appointmenttime ==null) || (state.consultant == null && state.consultant?.data.id > 0))}
                          >
                            Schedule Appointment
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </CardContent>
          </Card>

          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="primary">
          Appointment Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for your providing the details. Your applointment is booked successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button className="appointmentSelected" onClick={()=>navigate("/myAppointments")}>
            Your Appointments
          </Button>
        </DialogActions>
      </Dialog>
      </>
    );

    function addTimeToDay(newTimes) {
      const newAvail = availabilityState;
      if (newAvail.hasOwnProperty(year)) {
        if (newAvail[year].hasOwnProperty(month)) {
          newAvail[year][month][activeDay] = newTimes;
        } else {
          newAvail[year][month] = {
            [activeDay]: newTimes,
          };
        }
      } else {
        newAvail[year] = {
          [month]: {
            [activeDay]: newTimes,
          },
        };
      }
      setAvailabilityState(newAvail);
      setQuickAvailability(
        makeQuickAvailability(convertAvailabilityForDatabase(newAvail))
      );
    }

    function examineAvailabilityForDay(day) {
      if (
        availabilityState[year] &&
        availabilityState[year][month] &&
        availabilityState[year][month][day]
      ) {
        setTimes(availabilityState[year][month][day]);
      } else {
        setTimes(getDefaultTimes());
      }
      dispatch({ type: "SET_APPOINTMENT_DATE", payload: year + "/" + month + "/" + day })
      setActiveDay(day);
    }

    function addTimesToDay(day) {
      const newAvail = { ...availabilityState };
      if (newAvail[year]) {
        if (newAvail[year][month]) {
          if (newAvail[year][month][day]) {
            newAvail[year][month][day] = combineTimeArrays(
              newAvail[year][month][day],
              times
            );
          } else {
            newAvail[year][month][day] = times;
          }
        } else {
          newAvail[year][month] = {
            [day]: times,
          };
        }
      } else {
        newAvail[year] = {
          [month]: {
            [day]: times,
          },
        };
      }
      
      setAvailabilityState(newAvail);
      setQuickAvailability(
        makeQuickAvailability(convertAvailabilityForDatabase(newAvail))
      );
    }
  };
};

export default CalendarTemplate;