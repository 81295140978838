import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;

  const [selectedDate, setSelectedDate] = useState(props.defaultValue);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(dayjs(value));
    }
  }, [value]);

  function _onChange(date) {
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toISOString();
        setValue(ISODateString);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  }
  // console.log(selectedDate)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker sx={{border: 0, borderBottom: '1px solid', borderRadius: 0, borderBottomColor: '#50514F'}}         
           {...field}       
          label={props.label}  
          variant='filled'      
          value={selectedDate}          
          views={["year"]}
          onChange={_onChange}          
          error={isError}
          minDate={props.minDate}
          maxDate={props.maxDate}
          invalidDateMessage={isError && error}
          helperText={isError && error}          
        />
        
    </LocalizationProvider>

  );
}
