import { createSlice } from '@reduxjs/toolkit';
import { addUserPayment,fetchUserPayment } from '../thunks/payment';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchUserPayment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUserPayment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });   

    builder.addCase(addUserPayment.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(addUserPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
      builder.addCase(addUserPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });  
  },
});

export const paymentReducer = paymentSlice.reducer;
