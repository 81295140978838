import { isEmpty } from 'lodash';
import React from 'react';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';

const PrivateRoute = ({ children }) => {
    const {user, loading, error, dispatch} = useContext(AuthContext);    
    const isLoggedIn = (user) => {   
    const token = localStorage.getItem('accessToken'); 
    const user1 = localStorage.getItem('user');    
    if (!(token == null) || !(user == null) || !(isEmpty(user))) {
      return true;
    }      
    return false
  }
  console.log(user)
  return isLoggedIn(user) ? children : <Navigate to="/login"  
  />
};

export default PrivateRoute;