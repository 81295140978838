import React, { useEffect,useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import { useSelector } from 'react-redux';
import { fetchAppointments, fetchUserAppointments } from '../../../store'
import { useThunk } from '../../../hooks/use-thunk'
import { StoryMakersTheme } from '../../../theme/storyMakersTheme';
import PersistentDrawerLeft from '../../Shared/Navbar/Navbar';
import AppointByDate from '../AppointByDate/AppointByDate';
import { AuthContext } from '../../Context/AuthContext';
const ADMIN = process.env.REACT_APP_ADMIN;
const MyAppointMents = () => {  
    const {user } = useContext(AuthContext);
    const [doFetchAppointments] = useThunk(fetchAppointments);
    const [doFetchUserAppointments] = useThunk(fetchUserAppointments);
    const { data } = useSelector((state) => {
        return state.appointments;
      });
    
      useEffect(() => {        
        if(user?.email !== ADMIN) {
          doFetchUserAppointments(user?.email);
        } else {
          doFetchAppointments();
        }
      }, []);

  return (
    <ThemeProvider theme={StoryMakersTheme}>      
        <CssBaseline />
        <PersistentDrawerLeft />
        <AppointByDate data={data}/>            
    </ThemeProvider>
  );
};

export default MyAppointMents;