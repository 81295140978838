import React, { useState,useEffect } from 'react';
import { FaFacebook, FaGithub, FaGoogle } from 'react-icons/fa';
import { useNavigate } from "react-router-dom"
import { handleLoginWithProvider } from './LoginManager';
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import toast, { Toaster } from 'react-hot-toast';
import useFetch from '../../../hooks/useFetch';
import { useGoogleLogin } from "@react-oauth/google"
const CLIENT_ID = '1093498523826-37da6um3lov8t5vpgvidn4lb3lhga5gh.apps.googleusercontent.com'
const baseUrl = process.env.REACT_APP_BASE_URL;

const SocialSignUp = ({ handleResponse }) => {
    const navigate = useNavigate()
    const [ user, setUser ] = useState([]);    
    const [error, setError] = useState({})
    const loginToGoogle = useGoogleLogin({
        scopes: ["profile", "email"],
		onSuccess: tokenResponse => {                            
			localStorage.setItem("loginWith", "Google")
			localStorage.setItem("accessToken", tokenResponse.access_token)
			navigate("/appointment")
		},
	})

    const handleLogin = async googleData => {
        const res = await axios.post(`${baseUrl}/auth/google`, {
           
            body: JSON.stringify({
            token: googleData.tokenId
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
        const data = await res.json()
        // store returned user somehow
      }

    return (
        <div>
            <div className="social-media">            
                <div className="social-icon" onClick={() => loginToGoogle()}>
                    <FaGoogle/>
                </div>
                <div className="social-icon">
                    <FaFacebook />
                </div>                
            </div>
            {error.length && <h6 className="text-danger text-center p-2">{error}</h6>}

        </div>
    );
};

export default SocialSignUp;