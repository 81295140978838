import '../App.css'
import { createTheme,responsiveFontSizes  } from "@mui/material/styles";
import Opensans300 from '../assets/fonts/open-sans-v35-cyrillic-300.woff2'
import Opensans500 from '../assets/fonts/open-sans-v35-cyrillic-500.woff2'
import Opensans600 from '../assets/fonts/open-sans-v35-cyrillic-600.woff2'
import Opensans700 from '../assets/fonts/open-sans-v35-cyrillic-700.woff2'
import Opensans800 from '../assets/fonts/open-sans-v35-cyrillic-800.woff2'
import OpensansItalic from '../assets/fonts/open-sans-v35-cyrillic-italic.woff2'
import OpensansRegular from '../assets/fonts/open-sans-v35-cyrillic-regular.woff2'


let StoryMakersTheme = createTheme({
    mode: "dark",
    palette: {
        primary: {
            main: "#50514F",
            light: "#70C1B3",
            dark: "#50514F"
        },

        secondary: {
            main: '#E8EEEC',           
            light: "#70C1B3",
            dark: "#50514F"
        },
       
        background: {
            default: "#FFF"
        },
        typography: {
            fontFamily: 'StoryMakers',
            color: "#FFF",
            // fontSize:'0.875rem',
            h5: {
                textTransform: 'uppercase',
                fontWeight: 500,
            }
        },
    },
    typography: {      
       
        fontFamily: '"StoryMakers"',        
        color: "#E8EEEC",
        // fontSize:'0.875rem'
    },
    components: {
        MuiCssBaseline: {
            MuiCssBaseline: {
                styleOverrides: `                
                @font-face {
                  font-display: swap; 
                  font-family: 'StoryMakers';
                  font-style: normal;
                  font-weight: 300;
                  src: local('StoryMakers'), local('StoryMakers-Regular'), url(${OpensansRegular}) format('woff2');
                }      
                
                @font-face {
                  font-display: swap; 
                  font-family: 'StoryMakers';
                  font-style: normal;
                  font-weight: 500;
                  src: local('StoryMakers'), local('StoryMakers-500'), url(${Opensans500}) format('woff2');
                }
                
                @font-face {
                  font-display: swap; 
                  font-family: 'StoryMakers';
                  font-style: normal;
                  font-weight: 600;
                  src: local('StoryMakers'), local('StoryMakers-600'), url(${Opensans600}) format('woff2');
                }
               
                @font-face {
                  font-display: swap; 
                  font-family: 'StoryMakers';
                  font-style: normal;
                  font-weight: 700;
                  src: local('StoryMakers'), local('StoryMakers-700'), url(${Opensans700}) format('woff2');
                }
              
                @font-face {
                  font-display: swap; 
                  font-family: 'StoryMakers';
                  font-style: normal;
                  font-weight: 800;
                  src: local('StoryMakers'), local('StoryMakers-800'), url(${Opensans800}) format('woff2');
                }
                `,
              }
        },
             
        MuiDrawer: {
            styleOverrides: {
                root: {
                    backgroundColor: "#50514F",
                    color: "#E8EEEC",
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#E8EEEC",
                    // fontSize:'0.875rem'
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    color: "#50514F"
                }
            }

        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFF"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#35825B',
                    minWidth: 100,
                    height: 35,
                    margin: 2,
                    //border: "1px solid #E8EEEC",
                    color: "#70c1b3",
                    '&:hover': {
                        color: '#E8EEEC',
                        //border: "1px solid #35825B",
                        backgroundColor: '#70C1B3',
                    }
                },
                text: {
                    color: "#E8EEEC"
                }
            },
            variants: [{
                props: { variant: "storymaker" },
                style: {
                    color: "#E8EEEC"
                }
            }]
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1920
        }
    },
    zIndex: {
        appBar: 1150
    }
});
StoryMakersTheme.typography.h5 = {
    fontSize: '1.5rem',
    [StoryMakersTheme.breakpoints.up('xs')]: {
        fontSize: '0.875rem',
    },
    [StoryMakersTheme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    [StoryMakersTheme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  };

  StoryMakersTheme.typography.subtitle1 = {
    fontSize: '1rem',
    [StoryMakersTheme.breakpoints.up('xs')]: {
        fontSize: '0.6rem',
    },
    [StoryMakersTheme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
    [StoryMakersTheme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  };

  StoryMakersTheme.typography.subtitle2 = {
    fontSize: '1rem',
    [StoryMakersTheme.breakpoints.up('xs')]: {
        fontSize: '0.6rem',
    },
    [StoryMakersTheme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
    },
    [StoryMakersTheme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
    },
  };

  StoryMakersTheme.typography.body1 = {
    fontSize: '0.875rem',
    [StoryMakersTheme.breakpoints.up('xs')]: {
        fontSize: '0.875rem',
    },
    [StoryMakersTheme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [StoryMakersTheme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  };

  StoryMakersTheme.typography.body2 = {
    fontSize: '0.875rem',
    [StoryMakersTheme.breakpoints.up('xs')]: {
        fontSize: '0.875rem',
    },
    [StoryMakersTheme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [StoryMakersTheme.breakpoints.up('md')]: {
      fontSize: '0.875rem',
    },
  };
  StoryMakersTheme.typography.h6 = {
    fontSize: '1rem',
    [StoryMakersTheme.breakpoints.up('xs')]: {
        fontSize: '0.875rem',
    },
    [StoryMakersTheme.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
    [StoryMakersTheme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  };

// StoryMakersTheme = responsiveFontSizes(StoryMakersTheme)

export { StoryMakersTheme };