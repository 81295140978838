import { configureStore } from '@reduxjs/toolkit';
import { appointmentsReducer } from './slices/appointmentsSlice';
import { advisersReducer } from './slices/advisersSlice';
import { chartReducer } from './slices/chartsSlice';
import { paymentReducer } from './slices/paymentSlice';
import { questionnaireReducer } from './slices/questionnaireSlice';

export const store = configureStore({
  reducer: {
    appointments: appointmentsReducer,
    advisers:advisersReducer,
    payment:paymentReducer,
    questionnaire:questionnaireReducer,
    chartData:chartReducer
  },
});

export * from './thunks/fetchAppointments';
export * from './thunks/addAppointment';
export * from './thunks/fetchAdvisers';
export * from './thunks/fetchChartData';
export * from './thunks/payment';
export * from './thunks/questionnaire'
