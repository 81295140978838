import React, { useEffect, Suspense } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import { PaymentContextProvider } from '../../Context/PaymentContext';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import { useSelector } from 'react-redux';
import { useThunk } from '../../../hooks/use-thunk'
import { fetchUserPayment, getUserQnsStatus } from '../../../store';
import { StoryMakersTheme } from '../../../theme/storyMakersTheme';
import PersistentDrawerLeft from '../../Shared/Navbar/Navbar';
import Footer from '../../Shared/Footer/Footer';
import Payment from '../../Payment/Payment';
import ScheduleAppointment from './ScheduleAppointment';

const baseUrl = process.env.REACT_APP_BASE_URL;

const AppointMent = () => {
  const params = new URLSearchParams(useLocation().search);
  const [fetchUserQnsStatus] = useThunk(getUserQnsStatus);
  const [fetchUserPaymentStatus] = useThunk(fetchUserPayment);
  const {user, dispatch } = useContext(AuthContext);
  const qnsStatusObj = useSelector((state) => {
    return state.questionnaire
  });
  console.log(baseUrl);

  const paymentObj = useSelector((state) => {
    return state.payment
  });

  useEffect(() => {
    if (user?.email !=null) {
    fetchUserPaymentStatus({client_email_id:user?.email});
    fetchUserQnsStatus({client_email_id:user?.email});
    }
  },[user?.email])
  useEffect(() => {    
    const access_token = localStorage.getItem('accessToken')
    const fetchData = async (access_token) => {
      
      try {
        const { data: response } = await axios.get(`${baseUrl}/v1/userinfo?access_token=${access_token}`);

        dispatch({ type: "LOGIN_SUCCESS", payload: response })
      } catch (error) {
        console.error(error.message);
      }
    }
    if (user?.email ==null && access_token !=null) {
          fetchData(access_token);
    }
    
  }, [dispatch])
  
  return (
    <ThemeProvider theme={StoryMakersTheme}>
      <PaymentContextProvider>
        <CssBaseline />
        <PersistentDrawerLeft />
        <Suspense fallback={<div>Loading...</div>}>
          {!(qnsStatusObj?.data?.length > 0) && (
          <Payment data={params} />
          )}
          {(qnsStatusObj?.data?.length >0) && (
          <ScheduleAppointment qdata={{}} payment={{}} />
          )}
        </Suspense>
      </PaymentContextProvider>
      <Footer></Footer>
    </ThemeProvider>
  );
};

export default AppointMent;