import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const fetchAdvisers = createAsyncThunk('advisers/fetch', async () => {
  const response = await axios.get(`${baseUrl}/v1/advisers`);
  return response.data;
});

// DEV ONLY!!!
const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export { fetchAdvisers };
