import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const getUserQnsStatus = createAsyncThunk('qnsResp/fetch', async (data) => {
const response = await axios.post(`${baseUrl}/v1/getUserQnsStatus`, data);
  return response.data;
});

const addUserQnsResp = createAsyncThunk('qnsResp/add', async (data) => {
    const response = await axios.post(`${baseUrl}/v1/addUserQnsResp`, data);
    return response.data;
  });

export { addUserQnsResp,getUserQnsStatus };