import React, { useState, useEffect,Suspense  } from 'react';
import { AppointmentContextProvider } from '../../Context/AppointmentContext';
import { useSelector } from 'react-redux';
import { fetchAdvisers } from '../../../store'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import { StoryMakersTheme } from '../../../theme/storyMakersTheme';
import { useThunk } from '../../../hooks/use-thunk'
import CalendarTemplate from '../AppointMentTop/Calendar'

const baseUrl = process.env.REACT_APP_BASE_URL;

const ScheduleAppointment = (props) => {
    const [availability, setAvailability] = useState([])
    const [doFetchAdvisers, isLoadingAdvisers, loadingAdvisersError] = useThunk(fetchAdvisers);
    const { data } = useSelector((state) => {
      return state.advisers;
    });

    useEffect(() => {
      doFetchAdvisers();
    }, []);

    const userOffset = new Date().getTimezoneOffset()*60*1000;
    const utcDate = new Date((new Date()).getTime() + userOffset);
    const timezoneOffset = (new Date()).getTimezoneOffset();
    
    const Calendar = CalendarTemplate({
      availability,
      setAvailability,
      startTime: "6:00",
      endTime: "20:00"
    })
  return (
    <ThemeProvider theme={StoryMakersTheme}>     
        <AppointmentContextProvider>
        <CssBaseline />
          <Calendar 
          data={props} 
          advisers={data}
          />
        </AppointmentContextProvider>   
        </ThemeProvider>     
  );
};

export default ScheduleAppointment;