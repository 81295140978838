import {useCallback, useContext, useMemo,useState, useRef ,React} from 'react';
import { Fragment } from 'react';
import { Grid, Typography,Input, CssBaseline,Button, InputLabel,InputAdornment} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Radio from "@mui/material/Radio";
import DataTable from '../datatable';
import { InputField, CheckboxField, SelectField } from '../../FormFields';
import { FormContext } from '../../Questionnaire';

const formatting_options = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
}
let dollarString = new Intl.NumberFormat("en-US", formatting_options);

const adv_qns = [
    {
      id:"q1",
      question:"Q1: Choose between playing game A or B below. If p = 0, A is preferred (A is more profitable than B with probability 1). If p = 1, B is preferred (B is more profitable than A with probability 1). What is the smallest value of p such that you prefer B over A? There is no right answer.",
      api:"getRiskAversionMetricQ1",
      thresold:50,
      datakey:{aew: null,aw1p:null, awp:null, bew:null, bw1p:null, bwp:null, p:null},
      legends:{aew: "A E[winnings]",aw1p:"A[win w/ prob 1 - p] ", awp:"A[win w/ prob p]", bew:"B E[winnings]", bw1p:"B[win w/ prob 1 - p]", bwp:"B[win w/ prob p]", p:"P"},
      columns: [{ field: 'p', headerName: 'p', minWidth: 30 ,valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      } },
      { field: 'awp', headerName: 'Win with Probability p',minWidth: 160,
      headerClassName: 'super-app-theme-A-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-A-cell', valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      } },      
      { field: 'aw1p', headerName: 'Win with Probability 1 - p',minWidth: 160,
      headerClassName: 'super-app-theme-A-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability 1 - p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      } },
      { field: 'aew', headerName: 'E[winnings]',minWidth: 100,headerClassName: 'super-app-theme-A-header',
      cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      } },
      { field: 'bwp', headerName: 'Win with Probability p',minWidth: 160,
      headerClassName: 'super-app-theme-B-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-B-cell',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      }},
      { field: 'bw1p', headerName: 'Win with Probability 1 - p',minWidth: 160,
      headerClassName: 'super-app-theme-B-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability 1 - p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-B-cell',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      }},     
      { field: 'bew', headerName: 'E[winnings]',
      headerClassName: 'super-app-theme-B-header',
      cellClassName: 'super-app-theme-B-cell',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      }},{ field: 'preference', headerName: '',minWidth: 150 }]
    },
    {
      id:"q2",
      question:"Q2: Choose between playing game A or B below. If p = 0, A is preferred (A is more profitable than B with probability 1). If p = 1, B is preferred (B is more profitable than A with probability 1). What is the smallest value of p such that you prefer B over A? There is no right answer.",
      api:"getRiskAversionMetricQ2",
      thresold:20,
      datakey:{aew: null,aw1p:null, awp:null, bew:null, bw1p:null, bwp:null, p:null},
      legends:{aew: "A E[winnings]",aw1p:"A[win w/ prob 1 - p] ", awp:"A[win w/ prob p]", bew:"B E[winnings]", bw1p:"B[win w/ prob 1 - p]", bwp:"B[win w/ prob p]", p:"P"},
      columns: [{ field: 'p', headerName: 'p', minWidth: 30 ,valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString()} %`;
      } },
      { field: 'awp', headerName: 'Win with Probability p',minWidth: 160,
      headerClassName: 'super-app-theme-A-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-A-cell', valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      } },      
      { field: 'aw1p', headerName: 'Win with Probability 1 - p',minWidth: 160,
      headerClassName: 'super-app-theme-A-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability 1 - p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      } },
      { field: 'aew', headerName: 'E[winnings]',minWidth: 100,headerClassName: 'super-app-theme-A-header',
      cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      } },
      { field: 'bwp', headerName: 'Win with Probability p',minWidth: 160,
      headerClassName: 'super-app-theme-B-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-B-cell',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      }},
      { field: 'bw1p', headerName: 'Win with Probability 1 - p',minWidth: 160,
      headerClassName: 'super-app-theme-B-header',
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'Win with '}
          <div>
            {'probability 1 - p'}
          </div>
        </span>
      ),
      cellClassName: 'super-app-theme-B-cell',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      }},     
      { field: 'bew', headerName: 'E[winnings]',
      headerClassName: 'super-app-theme-B-header',
      cellClassName: 'super-app-theme-B-cell',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return dollarString.format(params.value);
      }},{ field: 'preference', headerName: '',minWidth: 150 }]
    }
  ]

export default function RiskAversion(props) {   

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
  useContext(FormContext);

  

const incomeRef = useRef(0)    
  const {
    formField: {
      firstName,
      lastName,
      mobile,
      age,
      netWorth,
      savings,
      retirementAgeTarget,
      grossIncome,
      preferInvestment,
      currentAssetAllocation
    }
  } = props;

  const [gross_income,setGrossIncome] = useState(formData.gross_income);  
 

  return (
    <Fragment>
    <CssBaseline />     
      <Grid container spacing={3}>
      <Grid item sm={12}>
      <Typography variant="h6" color="primary" gutterBottom>
        Risk Aversion
      </Typography>
      </Grid>
      <Grid item sm={12}>
      <Typography variant="h6" color="primary" gutterBottom>
       Total Gross Income ${gross_income}
      </Typography>
      </Grid>
        {+gross_income > 0 && (
        <Grid item sm={12}>
        {adv_qns.map((q)=>(            
            <DataTable qdata={q} income={+gross_income} groupingCols={true}/>
        ))}        
        </Grid>
        )}        
        </Grid>
        </Fragment>
  )}