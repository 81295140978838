import React,{Fragment} from 'react';
import { Box } from '@mui/material';
import { DataGrid,GridToolbar,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'

const formatting_options = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
}
let dollarString = new Intl.NumberFormat("en-US", formatting_options);

const columns = [
    { field: 'id', headerName: 'Appointment ID' },
    { field: 'fname', headerName: 'First Name' },
    { field: 'lname', headerName: 'Last Name' },
    { field: 'mobile', headerName: 'Mobile'},
    { field: 'client_email_id', headerName: 'Email' },
    { field: 'appointment_date', headerName: 'Appointment Date'},
    { field: 'birth_year', headerName: 'Birth Year'},    
    { field: 'retirement_year', headerName: 'Target Retirement Year'},
    { field: 'net_worth', headerName: 'Net Worth',valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    }},
    { field: 'saving_pct', headerName: 'saving(%)'},
    { field: 'gross_income', headerName: 'Gross Annual Income',valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    }},
    { field: 'gen_q1', headerName: 'General Question 1'},
    { field: 'gen_q2', headerName: 'General Question 2'},
    { field: 'asset_allocations', headerName: 'Asset Allocations'},
    { field: 'liquidity', headerName: 'Liquidity'},
    { field: 'risk_aversion_q1', headerName: 'Risk Aversion Q1'},
    { field: 'risk_aversion_q2', headerName: 'Risk Aversion Q2'},
    { field: 'loss_aversion_q1', headerName: 'Loss Aversion Q1'},
    { field: 'loss_aversion_q2', headerName: 'Loss Aversion Q2'},
    { field: 'reflection_q1', headerName: 'Reflection Q1'},
    { field: 'reflection_q2', headerName: 'Reflection Q2'}
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbar />        
      </GridToolbarContainer>
    );
  }

const AppointByDate = (props) => {
    return (
        <Fragment>
            <Box component="main" sx={{ flexGrow: 1, p: 3,backgroundColor:"#E8EEEC" }}>
                
            <div className="d-flex justify-content-center p-2">
                <p className="brand-color text-center">My Appointments</p>                
            </div>
            <DataGrid 
             rows={props.data}
             columns={columns}
             pageSize={12} 
             slots={{
                toolbar: CustomToolbar,
              }}
            >
            </DataGrid>            
        </Box>
        </Fragment>
    );
};

export default AppointByDate;