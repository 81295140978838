import { createSlice } from '@reduxjs/toolkit';
import { fetchAppointments, fetchUserAppointments } from '../thunks/fetchAppointments';
import { addAppointment } from '../thunks/addAppointment';


const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: {
    isLoading: false,
    newAppointment:[],
    data: [],
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchUserAppointments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserAppointments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUserAppointments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(fetchAppointments.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAppointments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(addAppointment.pending, (state, action) => {
      state.isLoading = true;      
    });
    builder.addCase(addAppointment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.newAppointment.push(action.payload);
    });
    builder.addCase(addAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const appointmentsReducer = appointmentsSlice.reducer;
