import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { AuthContextProvider } from './components/Context/AuthContext';
import { GoogleOAuthProvider,GoogleLogin } from "@react-oauth/google"
const GOOGLE_CLIENT_ID = '1093498523826-37da6um3lov8t5vpgvidn4lb3lhga5gh.apps.googleusercontent.com'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>          
        <Provider store={store}>
          <App />
        </Provider>                
      </GoogleOAuthProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

