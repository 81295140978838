import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const fetchAppointments = createAsyncThunk('appointment/fetch', async () => {
  const response = await axios.get(`${baseUrl}/v1/appointment`);
  return response.data;
});

const fetchUserAppointments = createAsyncThunk('user-appointment/fetch', async (user) => {
  const response = await axios.get(`${baseUrl}/v1/appointment/user`,{ params: {id:user}});
  return response.data;
});

// DEV ONLY!!!
const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export { fetchAppointments,fetchUserAppointments };