import React, { Fragment, useContext } from 'react';
import { Grid, CssBaseline, InputLabel, Box, Typography } from '@mui/material';
import { Field } from 'formik';
import { InputField, CheckboxField, SelectField, DatePickerField } from '../../FormFields';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormContext } from '../../Questionnaire';
export default function Reflection(props) {
  const {
    formField: {
      fname, lname, mobile, birth_year, retirement_year, net_worth, saving_pct, gross_income, 
      gen_q1, gen_q2, asset_allocations,liquidity, risk_aversion_q1, risk_aversion_q2,
       loss_aversion_q1, loss_aversion_q2, reflection_q1, reflection_q2
    }
  } = props;
  const { formData, setFormData } = useContext(FormContext);
  const formatting_options = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }
  let dollarString = new Intl.NumberFormat("en-US", formatting_options);
  let a1 = dollarString.format(4 * formData.getLossAversionMetricQ1.wit);
  let b1 = dollarString.format(4 * formData.getLossAversionMetricQ1.wit * 3);
  let qns1_choice_a = "Win "+a1+" with with probability 1"; 
  let qns1_choice_b = "Win "+b1+" with probability 1/3 and $0.00 with probability 2/3";

  let a2 = dollarString.format(- 4 * formData.getLossAversionMetricQ1.wit);
  let b2 = dollarString.format(- 4 * formData.getLossAversionMetricQ1.wit * 3);
  let qns2_choice_a = "Win "+a2+" with with probability 1"; 
  let qns2_choice_b = "Win "+b2+" with probability 1/3 and $0.00 with probability 2/3";


  return (
    <Fragment>
      <CssBaseline />
      <Typography variant="h6" color="primary" gutterBottom>
        Reflection Questions
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>

          <Typography variant="h6" color="primary" gutterBottom>
            Question 1: You must play game A or B below. Between the two options, which do you choose? There is no right answer.
          </Typography>
          <div role="group" aria-labelledby="my-radio-group">
            <InputLabel>
              <Field type="radio" name="reflection_q1" value={qns1_choice_a} />
              {qns1_choice_a}
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="reflection_q1" value={qns1_choice_b} />
              {qns1_choice_b}
            </InputLabel>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>

          <Typography variant="h6" color="primary" gutterBottom>
            Question 2: You must play game A or B below. Between the two options, which do you choose? There is no right answer.
          </Typography>
          <div role="group" aria-labelledby="my-radio-group">
            <InputLabel>
              <Field type="radio" name="reflection_q2" value={qns2_choice_a} />
              {qns2_choice_a}
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="reflection_q2" value={qns2_choice_b} />
              {qns2_choice_b}
            </InputLabel>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}