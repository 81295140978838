import { useCallback, useContext, useMemo, useState, useRef, React } from 'react';
import { Fragment } from 'react';
import { Grid, Typography, Input, Button, CssBaseline, InputLabel, InputAdornment } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { InputField, CheckboxField, SelectField } from '../../FormFields';
import { FormContext } from '../../Questionnaire';
import DataTable from '../datatable';

const formatting_options = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
}
let dollarString = new Intl.NumberFormat("en-US", formatting_options);

const adv_qns = [
  {
    id: "q3",
    question: "Question 1: A fair coin is flipped. If the coin lands tails, you win money. If heads, you 'win' $d (<0). For d = 0, you play (you win money or win/lose money with equal probability) the game. As d decreases from 0 there is a point at which you choose not to play. For example, the final row is a pure gamble with 0 expected winnings. What is the largest value (on the number line) of d at which you would choose to stop playing the game? There is no right answer.",
    api: "getLossAversionMetricQ1",
    datakey: { p: null, wih: null, wit: null, ew: null },
    legends: { p: "d", wih: "Win if heads (d)", wit: "Win if tail", ew: "E[winnings]" },
    columns: [{ field: 'p', headerName: 'd',align: 'center', minWidth: 50 ,valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    }  },
    { field: 'wih', headerName: 'Win if heads',align: 'center',minWidth: 150,
    headerClassName: 'super-app-theme-A-header',
    cellClassName: 'super-app-theme-A-cell', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    } },      
    { field: 'wit', headerName: 'Win if tail',align: 'center',minWidth: 150,
    headerClassName: 'super-app-theme-A-header',
    cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    } },
    { field: 'ew', headerName: 'E[winnings]',align: 'center',minWidth: 150,headerClassName: 'super-app-theme-A-header',
    cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    } },
    { field: 'preference', headerName: '',align: 'center',minWidth: 150 }]
  },
  { 
    id: "q4",
    question: "Question 2: A fair coin is flipped. If the coin lands tail, you win money. If heads, you 'win' $d (<0). For d = 0, you play (you win money or win/lose money with equal probability) the game. As d decreases from 0 there is a point at which you choose not to play. For example, the final row is a pure gamble with 0 expected winnings. What is the largest value (on the number line) of d for which you do not play the game? There is no right answer.",
    api: "getLossAversionMetricQ2",
    datakey: { p: null, wih: null, wit: null, ew: null },
    legends: { p: "d", wih: "Win if heads", wit: "Win if tail", ew: "E[winnings]" },
    columns: [{ field: 'p', headerName: 'd',align: 'center', minWidth: 50 ,valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    }  },
    { field: 'wih', headerName: 'Win if heads',align: 'center',minWidth: 150,
    headerClassName: 'super-app-theme-A-header',
    cellClassName: 'super-app-theme-A-cell', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    } },      
    { field: 'wit', headerName: 'Win if tail',align: 'center',minWidth: 150,
    headerClassName: 'super-app-theme-A-header',
    cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    } },
    { field: 'ew', headerName: 'E[winnings]',align: 'center',minWidth: 150,headerClassName: 'super-app-theme-A-header',
    cellClassName: 'super-app-theme-A-cell',valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return dollarString.format(params.value);
    } },
    { field: 'preference', headerName: '',align:'center',minWidth: 150 }]
  }
]

export default function RiskAversion(props) {
  const incomeRef = useRef(0)

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(FormContext);
  const [grossIncome, setGrossIncome] = useState(+formData.gross_income);
  const {
    formField: {
      firstName,
      lastName,
      mobile,
      age,
      netWorth,
      savings,
      retirementAgeTarget,
      cf_income,
      preferInvestment,
      currentAssetAllocation
    }
  } = props;

  return (
    <Fragment>
      <CssBaseline />
      <Typography variant="h6" color="primary" gutterBottom>
        Loss Aversion
      </Typography>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Typography variant="h6" color="primary" gutterBottom>
            Total Gross Income ${grossIncome}
          </Typography>
        </Grid>
        {grossIncome > 0 && (
         <Grid item sm={12}>
            {adv_qns.map((q) => (              
              <DataTable qdata={q} income={grossIncome} groupingCols={false}/>
            ))}
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}