import { useState,useRef, useEffect, useContext } from "react";
import { PaymentContext } from "../Context/PaymentContext";
import { Button, Stack,Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from '../../images/g557.png'
import { fetchUserPayment, addUserPayment,getUserQnsStatus } from './../../store'
import { useThunk } from './../../hooks/use-thunk'
import { useSelector } from 'react-redux';
import { AuthContext } from "../Context/AuthContext";
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
export default function CheckoutForm({ paymentStatus }) {
  const navigate = useNavigate();
  const formMonthlyRef = useRef(null);
  const formYearlyRef = useRef(null);
  const [priceId, setPriceId] = useState("");
  const [doCreateUserAppointment, isCreatingUserAppointment, creatingUserAppointmentError] = useThunk(addUserPayment);
  const [fetchUserAppointment] = useThunk(fetchUserPayment);
  // const [fetchUserQnsStatus] = useThunk(getUserQnsStatus);
  const { state, dispatch } = useContext(PaymentContext)
  const { user } = useContext(AuthContext);

  // const qnsStatusObj = useSelector((state) => {
  //   return state.questionnaire
  // });

  const paymentObj = useSelector((state) => {
    return state.payment
  });


  useEffect(() => {    
    fetchUserAppointment({client_email_id:user?.email})
    // fetchUserQnsStatus({client_email_id:user.email})
    // doCreateUserAppointment({client_email_id:user.email, payment_details: paymentStatus })
    if (paymentStatus) {
      dispatch({ type: "SET_PAYMENT", payload: { checkout_session_id: paymentStatus } })      
      doCreateUserAppointment({client_email_id:user?.email, payment_details: paymentStatus })
    }
  }, [paymentStatus]);

  return (<>
    {(paymentObj?.data?.length == 0) && (<>
      <Stack
      xs={{ pt: 4, pb: 2 }}
      md={{ pt: 4, mb: 4 }}
      direction="row"
      spacing={2}
      justifyContent="center"
    >         
      <Typography variant="h5" color="primary" component="div">Choose either payment plan </Typography>
    </Stack> 
      <div className="price-table-container">
              
      <section>
        <form action= {`${baseUrl}/create-checkout-session`} ref={formMonthlyRef} method="POST">
          <input type="hidden" id="paymentmode" name="paymentmode" value="subscription"/>
          <input type="hidden" id="emailid" name="emailid" value={user?.email}/>
          <input type="hidden" id="basicPrice" name="priceId" value="price_1NigMBHULV9OkXqGFe62QNZ7"/>
          <img
            src={logo}
            width="100%"
            height="34%"
            />
          <div className="name">Monthly Payment Plan</div>
          <div className="price">$39.92</div>
          <div className="duration">per month <strong>(12 months only)</strong></div>
          <Stack
          xs={{ pt: 4, pb: 2 }}
          md={{ pt: 4, mb: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
          >
          <Button size="small" type="submit">Payment</Button>
        </Stack>
        </form>
      </section>
      <section>
        <form action= {`${baseUrl}/create-checkout-session`} ref={formYearlyRef} method="POST">
          <input type="hidden" id="paymentmode" name="paymentmode" value="payment"/>
          <input type="hidden" id="emailid" name="emailid" value={user?.email}/>
          <input type="hidden" id="proPrice" name="priceId" value="price_1NigJFHULV9OkXqGwNl9rfJo"/>
          <img
            src={logo}
            width="100%"
            height="37%"
            />
          <div className="name">One Time Payment Plan</div>
          <div className="price">$479.00</div>
          <div className="duration"></div>
          <Stack
          xs={{ pt: 4, pb: 2 }}
          md={{ pt: 4, mb: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button size="small" type="submit">Payment</Button>
        </Stack>
        </form>
      </section>
    </div>
      </>)
    }

    {(paymentObj?.data?.length > 0) && (
      <Box id="payment-message" sx={{ textAlign: "center" }}>
        <Typography variant="body2" color="primary" gutterBottom >Payment is completed successfully</Typography>
        <Button size="small" onClick={() => navigate("/questionnaire", { state: { paymentId: paymentStatus } })}>Answer the Questionnaire</Button>
      </Box>)}
  </>
  );
}