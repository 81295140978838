import { useReducer } from "react";
import { createContext } from "react"


const INITIAL_STATE = {    
    paymentDetails: null,
    loading: false,
    error: null,
}

export const PaymentContext = createContext(INITIAL_STATE);

const PaymentReducer = (state, action) =>{
    switch (action.type) {
        
        case "SET_PAYMENT":
                return {                      
                    paymentDetails: action.payload,                    
                    loading: false,
                    error: false
                }            
        default:
            return state;
    }
}

export const PaymentContextProvider = ({children}) =>{
    const [state, dispatch] = useReducer(PaymentReducer, INITIAL_STATE);
    
    return (
        <PaymentContext.Provider
            value={{
                state,
                dispatch
            }}
        >
            {children}
        </PaymentContext.Provider>
    )
}