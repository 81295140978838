export default {
  formId: 'checkoutForm',
  formField: {
    fname: {
      name: 'fname',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    lname: {
      name: 'lname',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    mobile: {
      name: 'mobile',
      label: 'Mobile*',
      requiredErrorMsg: 'Mobile number is required'
    },
    birth_year: {
      name: 'birth_year',
      label: 'Year of Birth*',
      requiredErrorMsg: 'Birth year is required',
      invalidErrorMsg: 'Birth year is not valid'
    },
    retirement_year: {
      name: 'retirement_year',
      label: 'Target Retirement Year*',
      requiredErrorMsg: 'Target Retirement year is required',
      invalidErrorMsg: 'Target Retirement year is not valid'
    },
    job: {
      name: 'job',
      label: 'Job Title'
    },

    net_worth: {
      name: 'net_worth',
      label: '$ Net Worth*',
      requiredErrorMsg: 'Net Worth is required',
      invalidErrorMsg: 'Net Worth is not valid'
    },
    saving_pct: {
      name: 'savings_pct',
      label: '% Savings from Income*',
      requiredErrorMsg: 'Saving from income is required',
      invalidErrorMsg: 'Saving  % is not valid'
    },
    gross_income: {
      name: 'gross_income',
      label: '$ Gross Annual Income*',
      requiredErrorMsg: 'Enter your Gross Annual Income',
      invalidErrorMsg: 'Gross Annual Income is not valid'
    },
    cal_term: {
      name: "cal_term",
      label: "Short-term reserves(%)"
    },
    cal_bonds:{
      name: "cal_bonds",
      label: "Bonds(%)"
    },
    cal_stocks:{
      name: "cal_stocks",
      label: "Stocks(%)"
    },

    liquidity: {
      name: 'liquidity',
      label: 'Life events in the next 12 months requiring liquidity (marriage, child birth, educational expenses, ...)'
    },
    otherinfo:{
      name: "otherinfo",
      label:"Other information you would like to share?"
    }
  }
};
