import { createContext } from 'react';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
// import Home from './components/Home/Home/Home';
import SignInForm from './components/Login/LoginMain/SignInForm';
import AppointMent from './components/AppointMent/AppointMent/AppointMent';
import PrivateRoute from './components/Login/PrivateRoute/PrivateRoute';
import Completion from './components/Payment/Completion';
import Questionnaire from './components/Questionnaire/Questionnaire';
import MyAppointments from './components/AppointMent/AppointMent/MyAppointments';
import ScheduleAppointment from './components/AppointMent/AppointMent/ScheduleAppointment';

export const UserContext = createContext();

const router = createBrowserRouter([
  { path: '/', element: <PrivateRoute><AppointMent /></PrivateRoute> },
  { path: '/Logout', element: <SignInForm /> },
  { path: '/login', element: <SignInForm /> },
  { path: '/appointment', element: <PrivateRoute><AppointMent /></PrivateRoute> },
  { path: '/myAppointments', element: <PrivateRoute><MyAppointments/></PrivateRoute> },
  { path: '/questionnaire', element: <PrivateRoute><Questionnaire/></PrivateRoute> },
  { path: '/scheduleAppointment', element:<PrivateRoute><ScheduleAppointment/></PrivateRoute>},
  { path: '/complete', element: <Completion /> }
])

function App() {
  return (<RouterProvider router={router} />);
}
export default App;
