import { createSlice } from '@reduxjs/toolkit';
import { fetchAdvisers } from '../thunks/fetchAdvisers';

const advisersSlice = createSlice({
  name: 'advisers',
  initialState: {
    isLoading: false,
    data: [],
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchAdvisers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAdvisers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAdvisers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });   
  },
});

export const advisersReducer = advisersSlice.reducer;
