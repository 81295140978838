import React, { useContext } from 'react';
import { Grid, CssBaseline, InputLabel, Typography, Divider } from '@mui/material';
import { Field } from 'formik';
import dayjs from 'dayjs';
import moment from 'moment';
import { InputField, CheckboxField, DatePickerField } from '../../FormFields';
import "../style.css"
import { FormContext } from '../../Questionnaire';

export default function BasicInfo(props) {
  const { formData, setFormData } = useContext(FormContext);
  const {
    formField: {
      fname, lname, mobile, birth_year, retirement_year, job, net_worth, saving_pct, gross_income,
      cal_term,cal_bonds,cal_stocks,  gen_q1, gen_q2, asset_allocations, liquidity, otherinfo, risk_aversion_q1, risk_aversion_q2, loss_aversion_q1,
      loss_aversion_q2, reflection_q1, reflection_q2
    },
    values
  } = props;
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography variant="h5" color="primary" gutterBottom>
        Personal Information
      </Typography>
      <Grid container spacing={2} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item>
          <InputField id="first-name" name={fname.name} label={fname.label} />
        </Grid>
        <Grid item>
          <InputField id="last-name" name={lname.name} label={lname.label} />
        </Grid>
        <Grid item>
          <InputField id="mobile" type="number" name={mobile.name} label={mobile.label} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item>
          <DatePickerField
            id="age"
            name={birth_year.name}
            label={birth_year.label}
            views={["year"]}
            minDate={dayjs(moment().subtract(85, "years"))}
            maxDate={dayjs(moment().subtract(18, "years"))}
            defaultValue={dayjs(moment().subtract(30, "years"))}
          />
        </Grid>
        <Grid item>
          <DatePickerField
            id="retirement-age"
            name={retirement_year.name}
            label={retirement_year.label}
            format="MM/dd/yy"
            views={["year", "month", "day"]}
            minDate={dayjs(moment().add(1, "years"))}
            maxDate={dayjs(moment().add(60, "years"))}
            defaultValue={dayjs(moment().add(30, "years"))}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item>
          <InputField id="job" name={job.name} label={job.label} />
        </Grid>
        <Grid item>
          <InputField id="networth" name={net_worth.name} label={net_worth.label} />
        </Grid>        
        <Grid item>
          <InputField id="gross_income" name={gross_income.name} label={gross_income.label} />
        </Grid>
        <Grid item>
          <InputField id="saving" type="number" name={saving_pct.name} label={saving_pct.label} />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary" id="my-radio-group">
            My current and future income sources (for example, salary, social security, pensions) are...
          </Typography>
          <div role="group" aria-labelledby="my-radio-group">
            <InputLabel>
              <Field type="radio" name="cf_income" value="Very unstable" />
              Very unstable
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="cf_income" value="Unstable" />
              Unstable
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="cf_income" value="Somewhat stable" />
              Somewhat stable
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="cf_income" value="Stable" />
              Stable
            </InputLabel>
            <InputLabel m={3}>
              <Field type="radio" name="cf_income" value="Very stable" />
              Very stable
            </InputLabel>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary" id="my-radio-group">Generally, I prefer an investment with little or no ups and downs in value, and I am willing to accept the lower returns these investments may make.
          </Typography>
          <div role="group" aria-labelledby="my-radio-group">
            <InputLabel>
              <Field type="radio" name="preferInvestment" value="Strongly disagree" />
              Strongly disagree
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="preferInvestment" value="Disagree" />
              Disagree
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="preferInvestment" value="Somewhat agree" />
              Somewhat agree
            </InputLabel>
            <InputLabel>
              <Field type="radio" name="preferInvestment" value="Agree" />
              Agree
            </InputLabel>
            <InputLabel m={3}>
              <Field type="radio" name="preferInvestment" value="Strongly agree" />
              Strongly agree
            </InputLabel>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item xs={12} sx={{display:"flex", justifyContent:"space-between"}}>
          <Typography variant="subtitle1" color="primary"
            id="current-asset-allocation" textAlign={"left"}>My current asset allocation.</Typography>
            <Typography variant="subtitle1" color="primary" textAlign={"right"}>Total: {+values["cal_term"] + (+values["cal_bonds"]) + (+values["cal_stocks"])} %</Typography>
        </Grid>
        <Grid item><InputField name={cal_term.name} type="number" label={cal_term.label} fullWidth /></Grid>
        <Grid item><InputField name={cal_bonds.name} type="number" label={cal_bonds.label} fullWidth /></Grid>
        <Grid item><InputField name={cal_stocks.name} type="number" label={cal_stocks.label} fullWidth /></Grid>        
      </Grid>
      <Divider />
      <Grid container spacing={3} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
        <Grid item xs={12}>
          <CheckboxField
            color="primary"
            name={liquidity.name}
            label={liquidity.label}
          />
        </Grid>
        
      </Grid>
      <Grid container spacing={3} sx={{ p: { xs: 0, sm: 1, md: 2 } }}>
      <Grid item xs={12}>
          <InputField
            color="primary"            
            name={otherinfo.name}
            label={otherinfo.label}            
            multiline={true}
            rows={2}
            variant='filled'
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
