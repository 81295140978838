import React, { useState, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import CheckoutPage from './CheckoutPage/QuestionnairePage'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import { Box } from '@mui/material';
import { StoryMakersTheme } from '../../theme/storyMakersTheme';
import PersistentDrawerLeft from '../../components/Shared/Navbar/Navbar';

export const FormContext = createContext();


export default function Questionnaire() {
  const { state } = useLocation();
  const [formData, setFormData] = useState({});
  // console.log(state)
  return (
    <ThemeProvider theme={StoryMakersTheme}>
      <CssBaseline />
      <PersistentDrawerLeft />
      <Box
        component="main"
        sx={{ flexGrow: 1, justifyContent: "center" }}
      >
        <FormContext.Provider 
         value={{ formData, setFormData }} 
        >
          <CheckoutPage payment={state} />
        </FormContext.Provider>
      </Box>
    </ThemeProvider>)
}