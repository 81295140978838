import React,{useContext} from 'react';
import Typography from "@mui/material/Typography";
import { FormContext } from '../../Questionnaire';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function QuestionnaireSuccess() {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext);

  return (
    <React.Fragment>
      <Typography variant="subtitle1" color="primary" gutterBottom className="text-white m-5 p-5 text-center">
        Thank you for your response. <br/> Please click below to schedule your appointment date and time.
      </Typography>      
    </React.Fragment>
  );
}

export default QuestionnaireSuccess;
