import checkoutFormModel from './checkoutFormModel';
import dayjs from 'dayjs';
import moment from 'moment';
const {
  formField: {
    fname, lname, mobile, birth_year, retirement_year,job, net_worth, saving_pct, gross_income, 
    cal_bonds,cal_term,cal_stocks,
    gen_q1, gen_q2, asset_allocations,liquidity, otherinfo, risk_aversion_q1, risk_aversion_q2, loss_aversion_q1, 
    loss_aversion_q2, reflection_q1, reflection_q2
  }
} = checkoutFormModel;

export default {
  [fname.name]: '',
  [lname.name]: '',
  [mobile.name]: '',
  [birth_year.name]: dayjs(moment().subtract(30, "years")),
  [retirement_year.name]: dayjs(moment().add(30, "years")),
  [job.name]:'',
  [net_worth.name]: '',
  [saving_pct.name]: '',
  [gross_income.name]: '',  
  [cal_bonds.name]: 0, 
  [cal_term.name]: 0,
  [cal_stocks.name]: 0,
  [liquidity.name] : '',
  [otherinfo.name]:''
};
