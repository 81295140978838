import * as Yup from 'yup';
import "yup-phone";

import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    fname, lname, mobile, birth_year, retirement_year, net_worth, saving_pct, gross_income,    
    cal_term,cal_stocks,cal_bonds, 
    gen_q1, gen_q2, asset_allocations,liquidity, risk_aversion_q1, 
    risk_aversion_q2, loss_aversion_q1, loss_aversion_q2, reflection_q1, reflection_q2
  }
} = checkoutFormModel;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default [
  Yup.object().shape({
    [fname.name]: Yup.string().required(`${fname.requiredErrorMsg}`),
    [lname.name]: Yup.string().required(`${lname.requiredErrorMsg}`),
    [mobile.name]: Yup.string().matches(phoneRegExp, 'Mobile number is not valid')
    .min(10, "Mobile number should be in 10 digit")
    .max(10, "Mobile number should be in 10 digit").required(`${mobile.requiredErrorMsg}`),
    [birth_year.name]: Yup.string().required(`${birth_year.requiredErrorMsg}`),
    [retirement_year.name]: Yup.string().required(`${retirement_year.requiredErrorMsg}`),
    [net_worth.name]: Yup.number().typeError("Please enter numeric value only").required(`${net_worth.requiredErrorMsg}`),
    [saving_pct.name]: Yup.number().min(0, '% Saving should be >= 0')
    .max(100,"% Saving should not be more 100")
    .typeError("Please enter numeric value only")
    .required(`${saving_pct.requiredErrorMsg}`),
    [gross_income.name]: Yup.number().min(0, 'Gross Annual Income should be >= 0').typeError("Please enter numeric value only").required(`${gross_income.requiredErrorMsg}`),    
    [cal_term.name]: Yup.number().min(0)
    .test("max", "Total should not exceed 100 %", function(value) {
      const { cal_bonds,cal_stocks,cal_term } = this.parent;      
      return value <= 100 - cal_stocks - cal_bonds;
    }),
    [cal_bonds.name]: Yup.number().min(0)
    .test("max", "Total should not exceed 100 %", function(value) {
      const { cal_bonds,cal_stocks,cal_term } = this.parent;      
      return value <= 100 - cal_term - cal_stocks;
    }),
    [cal_stocks.name]: Yup.number().min(0)    
    .test("max", "Total should not exceed 100 %", function(value) {
      const { cal_bonds,cal_stocks,cal_term } = this.parent;        
      return value <= 100 - cal_term - cal_bonds;
    })
  })
];
