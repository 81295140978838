import React, { useState } from 'react';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import SocialSignUp from './SocialSignUp';
import { useForm } from "react-hook-form";
import axios from 'axios';

import fulllogo_transparent_nobuffer from '../../../images/fulllogo_transparent_nobuffer.png';
import { FaFacebook, FaGithub, FaGoogle } from 'react-icons/fa';
import { useGoogleLogin } from "@react-oauth/google"
import { GoogleLogin } from 'react-google-login';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const SignIn = ({ handleResponse }) => {
    const { user, loading, error, dispatch } = useContext(AuthContext);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [show, setShow] = useState(true);

    setTimeout(() => {
        setShow(false);
    }, 10000)

    const onSubmit = async (event) => {
        dispatch({ type: "LOGIN_START" })
        try {
            const res = await axios.post(`${baseUrl}/v1/auth/login`, event);
            if(res.data.login){
            localStorage.setItem('user',res.data.user);            
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data.user })
            } 
            else {
                dispatch({ type: "LOGIN_FAILURE", payload: res.data })
            }
            swal({
                icon: res.data.login ? 'success':'error',
                text: res.data.login ? 'Successfully Sign In': res.data.msg,
                timer: 2000
            })
            res.data.login ? navigate("/") : navigate("/login")
        } catch (err) {
            dispatch({ type: "LOGIN_FAILURE", payload: err.response.data })
        }
    }

    const loginToGoogle = useGoogleLogin({
        scopes: ["profile", "email"],
		onSuccess: tokenResponse => {                                 
			localStorage.setItem("loginWith", "Google")
			localStorage.setItem("accessToken", tokenResponse.access_token)            
            //navigate("/")
			navigate("/appointment")
		},
	})

    return (
        // <form className="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
        //     <img src={`${fulllogo_transparent_nobuffer}`} alt="" className="pImg" />
        //     <p className="social-text">Sign in with social platforms</p>
        //     <div>
        //     <div className="social-media">
        //         <div className="social-icon" onClick={() => loginToGoogle()}>
        //             <FaGoogle/>
        //         </div>
        //         <div className="social-icon">
        //             <FaFacebook />
        //         </div>                
                
        //     </div>
        //   </div>
        // </form>

<form className="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
<img src={`${fulllogo_transparent_nobuffer}`} alt="" className="pImg" />
<h2 className="title">Sign in</h2>
<div className="input-field">
    <span className="fIcon"><FaEnvelope /></span>
    <input {...register("email", { required: true })} placeholder="Enter Your Email" type="email" />
</div>
{errors.email && <span className="text-warning">This field is required</span>}
<div className="input-field">
    <span className="fIcon"><FaLock /></span>
    <input {...register("password", { required: true })} type="password" placeholder="Enter Your Password" />
</div>
{errors.password && <span className="text-warning">This field is required</span>}
{error && <p className="text-danger">{error.message}</p>}
<button className="iBtn" type="submit" value="sign In" >
    {loading ? "..." : "Sign In"}
</button>
<p className="social-text">Sign in with social platforms</p>

<div>
<div className="social-media">
    <div className="social-icon" onClick={() => loginToGoogle()}>
        <FaGoogle/>
    </div>
    <div className="social-icon">
        <FaFacebook />
    </div>                
</div>
{/* {error.length && <h6 className="text-danger text-center p-2">{error}</h6>} */}

</div>
</form>
);
};

export default SignIn;