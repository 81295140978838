import { stubFalse } from "lodash";
import { useReducer } from "react";
import { createContext,useState, useEffect } from "react"


const INITIAL_STATE = {
    consultant: {data:{id: 1, name: 'John Henry, Ph.D', email: 'john@test.com', mobile: '23423423'}},
    appointmentDate: null,
    appointmenttime: null,    
    loading: false,
    error: null,
}

export const AppointmentContext = createContext(INITIAL_STATE);

const AppointmentReducer = (state, action) =>{
    switch (action.type) {
        case "SELECTED_CONSULTANT":
            return {
                consultant:action.payload,                
                appointmentDate: state.appointmentDate,
                appointmenttime: state.appointmenttime,                           
                loading: true,
                error: stubFalse,
            }
        case "SET_APPOINTMENT_DATE":     
            return {           
                consultant:state.consultant,     
                appointmentDate: action.payload,
                appointmenttime: null,                  
                loading: false,
                error: false,
            }
        case "SET_APPOINTMENT_TIME":
            return {  
                consultant:state.consultant,               
                appointmentDate: state.appointmentDate,
                appointmenttime: action.payload,                
                loading: false,
                error: false
            }
        
        case "UPDATE_QUESTIONNAIRE_RESP":
                    return {  
                        consultant:state.consultant,               
                        appointmentDate: state.appointmentDate,
                        appointmenttime: state.appointmenttime,
                        paymentDetails: state.paymentDetails,     
                        questionnaire:action.payload,
                        loading: false,
                        error: action.payload
                    }
        default:
            return state;
    }

}

export const AppointmentContextProvider = ({children}) =>{
    const [state, dispatch] = useReducer(AppointmentReducer, INITIAL_STATE);
    
    return (
        <AppointmentContext.Provider
            value={{
                state,
                dispatch
            }}
        >
            {children}
        </AppointmentContext.Provider>
    )

}